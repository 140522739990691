import { Button } from "../../Components/ui/button";
import { Input } from "../../Components/ui/input";
import { Label } from "../../Components/ui/label";
import { useState, useRef, useEffect } from "react";
import { DialogContent } from "../../Components/ui/dialog";
import { Dialog } from "../../Components/ui/dialog";
import { DialogTitle } from "../../Components/ui/dialog";
import { DialogHeader } from "../../Components/ui/dialog";
import { DialogDescription } from "../../Components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../Components/ui/select";
import axios from "axios"; // Axios for API requests
import { RootState } from "@/src/State/store";
import { useSelector } from "react-redux";
import { setworkspace } from "@/src/State/slices/AuthenticationSlice";
import { Weight } from "lucide-react";
import Papa from "papaparse";
import * as XLSX from "xlsx";
interface CreateListDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

interface ExcelRow {
  firstname: string;
  lastname: string;
  phoneno: string;
  location?: string; // Optional field
}


const CreateListDialog: React.FC<CreateListDialogProps> = ({
  open,
  onOpenChange,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState("");
  const [fileError, setFileError] = useState("");
  const [listName, setListName] = useState("");
  const [workspaceId, setWorkspaceId] = useState("0"); // Added state for workspace selection
  const [uploading, setUploading] = useState(false);
  const [apiUrlAdvAcc, setApiUrlAdvAcc] = useState("");

  const [selectedFile, setSelectedFile] = useState<File | null>(null); // Track selected file
  const wid = useSelector((state:RootState) => state.authentication.workspace_id);  
  

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch("/config.json");
        const config = await response.json();
        setApiUrlAdvAcc(config.ApiUrlAdvAcc);
       

      } catch (error) {
        console.error("Error loading config or fetching workspaces:", error);
      }
    };

    fetchConfig();
  }, []);

  useEffect(() => {
    if (!open) {
      setFileName("");
      setFileError("");
      setListName("");
      setWorkspaceId("0");
      setSelectedFile(null); // Clear the selected file when the dialog is closed
    }
  }, [open]);


  const handleDialogChange = (event: any) => {
    onOpenChange(event.target?.checked || false);
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files && event.target.files[0];
  
  //   if (file) {
  //     // Add support for txt, csv, and xlsx file types
  //     const validFileTypes = [
  //       "text/csv",
  //       "text/plain",
  //       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // XLSX MIME type
  //     ];
  
  //     // 25MB file size limit
  //     const maxFileSize = 25 * 1024 * 1024; // 25MB in bytes
  
  //     // Check for file type validity
  //     if (!validFileTypes.includes(file.type)) {
  //       setFileError("Please select a valid CSV, TXT, or XLSX file.");
  //       setFileName("");
  //       return;
  //     }
  
  //     // Check for file size validity
  //     if (file.size > maxFileSize) {
  //       setFileError("File size exceeds the 25MB limit.");
  //       setFileName("");
  //       return;
  //     }
  
  //     // Reset errors and set file name if all checks pass
  //     setFileError("");
  //     setFileName(file.name);
  //   } else {
  //     setFileError("Please select a file.");
  //   }
  // };


  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
  
    if (file) {

      setFileName(file.name); // Set the file name to display
      setSelectedFile(file);  // Store the selected file in state
      setFileError("");       // Clear any previous error

      // Add support for txt, csv, and xlsx file types
      const validFileTypes = [
        "text/csv",
        "text/plain",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // XLSX MIME type
      ];
  
      // 25MB file size limit
      const maxFileSize = 25 * 1024 * 1024; // 25MB in bytes
  
      // Check for file type validity
      if (!validFileTypes.includes(file.type)) {
        setFileError("Please select a valid CSV, TXT, or XLSX file.");
        setFileName("");
        return;
      }
  
      // Check for file size validity
      if (file.size > maxFileSize) {
        setFileError("File size exceeds the 25MB limit.");
        setFileName("");
        return;
      }
  
      try {
        // Validate required columns based on file type
        const requiredColumns = ["firstname", "lastname", "phoneno", "location"];
  
        if (file.type === "text/csv" || file.type === "text/plain") {
          // Validate CSV/TXT files
          await validateCSVOrTXTFile(file, requiredColumns);
        } else if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
          // Validate XLSX files
          await validateXLSXFile(file, requiredColumns);
        }
  
        // If validation passes
        setFileError("");
        setFileName(file.name);
      } catch (error: any) {
        setFileError(error.message || "An error occurred while validating the file.");
        setFileName("");
      }
    } else if(selectedFile) {
      setFileName("");
      return;
    }
    else{
      setFileError("Please Select File");
      return;
    }
  };
  
  

  const validateCSVOrTXTFile = (file: File, requiredColumns: string[]): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const fileContent = event.target?.result as string;
        const delimiter = fileContent.includes("|") ? "|" : ","; // Determine delimiter
        const headers = fileContent
          .split("\n")[0]
          .split(delimiter)
          .map((header) => header.trim().toLowerCase());

        // Check for missing columns
        const missingColumns = requiredColumns.filter((col) => !headers.includes(col));
        if (missingColumns.length > 0) {
          reject(new Error(`Missing necessary columns: ${missingColumns.join(", ")}`));
        } else {
          resolve();
        }
      };

      reader.onerror = () => {
        reject(new Error("Failed to read the file."));
      };

      reader.readAsText(file);
    });
  };

  const validateXLSXFile = (file: File, requiredColumns: string[]): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json<string[]>(worksheet, { header: 1 }) as string[][];

        if (!jsonData || jsonData.length === 0) {
          reject(new Error("File is empty."));
          return;
        }

        const headers = jsonData[0].map((header) => header.trim().toLowerCase());

        // Check for missing columns
        const missingColumns = requiredColumns.filter((col) => !headers.includes(col));
        if (missingColumns.length > 0) {
          reject(new Error(`Missing necessary columns: ${missingColumns.join(", ")}`));
        } else {
          resolve();
        }
      };

      reader.onerror = () => {
        reject(new Error("Failed to read the file."));
      };

      reader.readAsArrayBuffer(file);
    });
  };


  const validateListName = (value: string) => {
    const specialCharPattern = /[^a-zA-Z0-9 ]/; // Regex to check for special characters
    if (!specialCharPattern.test(value)) {
      setListName(value); // Only update the state if no special characters are found
    }
  };
  
  
  

  // const handleCompleteClick = async () => {
  //   console.log(fileName , wid , listName)
  //   if (!fileName || !wid || !listName) {
  //     setFileError("Please fill all required fields.");
  //     return;
  //   }

  //   const file = fileInputRef.current?.files?.[0];

  //   if (!file) {
  //     setFileError("File not found. Please re-upload.");
  //     return;
  //   }

  //   const formData = new FormData();
  //   formData.append("file", file);
  //   formData.append("listName", listName);
  //   formData.append("workspace_id", String(wid)); // Append workspace_id

  //   try {
  //     setUploading(true);
  //     const response = await axios.post(`${apiUrlAdvAcc}/contact_upload`, formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     });

  //     const response_data = response.data[0];
  //     console.log(response_data.Status);
  //     if (response_data.Status === "Success") {
  //       alert("File uploaded successfully.");
  //       onOpenChange(false);
  //     } else {
  //       setFileError(response.data.Status_Description || "An error occurred.");
  //     }
  //   } catch (error) {
  //     console.error("Upload Error:", error);
  //     setFileError("Failed to upload file. Please try again.");
  //   } finally {
  //     setUploading(false);
  //   }
  // };


  const handleCompleteClick = async () => {
    console.log(fileName, wid, listName);
  
    // Check if any field is missing or if the file is invalid
    if (!fileName || !wid || !listName) {
      setFileError("Please fill all required fields.");
      return;
    }

     // Ensure the file is valid
     if (!selectedFile) {
      setFileError("File not found. Please re-upload.");
      return;
    }
  
    const file = fileInputRef.current?.files?.[0];
  
    // If no file is selected, show an error
    if (!file) {
      setFileError("File not found. Please re-upload.");
      return;
    }
  
    const formData = new FormData();
  
    // Ensure that file is not undefined before appending
    if (file) {
      formData.append("file", file);
    }
  
    formData.append("listName", listName);
    formData.append("workspace_id", String(wid)); // Append workspace_id
  
    try {
      setUploading(true);
      const response = await axios.post(`${apiUrlAdvAcc}/contact_upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      const response_data = response.data[0];
      console.log(response_data.Status);
      if (response_data.Status === "Success") {
        alert("File uploaded successfully.");
        onOpenChange(false);
      } else {
        setFileError(response.data.Status_Description || "An error occurred.");
      }
    } catch (error) {
      console.error("Upload Error:", error);
      setFileError("Failed to upload file. Please try again.");
    } finally {
      setUploading(false);
    }
  };
  
  

  return (
    <Dialog open={open} onOpenChange={handleDialogChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-18px font-semibold text-[#09090B]">Create list</DialogTitle>
          <DialogDescription className="text-14px font-medium text-[#71717A] leading-[16.94px]">
            Create a new list of customers using their phone numbers.
          </DialogDescription>
        </DialogHeader>

        <form>
          <div className="mb-4 flex flex-col gap-[6px]">
            <Label htmlFor="list-name" className="text-14px font-medium text-[#020617]">Name</Label>
            <Input
              id="list-name"
              type="text"
              placeholder="Add your list name..."
              value={listName}
              onChange={(e) => validateListName(e.target.value)}
              className="text-[14px] font-normal placeholder:text-[#64748B]"
              />
          </div>

          <div className="flex flex-col w-full gap-[6px] my-5">
            <Label htmlFor="upload-file" className="text-[14px] font-medium text-[#0F172A]">Upload File</Label>
            <div className="flex items-center gap-2">
            <Input
              id="upload-file"
              type="text"
              value={fileName}
              readOnly
              className="flex-grow w-[80%] border-[#E2E8F0] text-[#020617] placeholder-[#64748B] placeholder:font-normal"
              placeholder="Choose File   No file chosen"
            />
            <Button
              type="button"
              className="bg-[#F1F5F9] w-[30%] text-[#0F172A] font-medium text-[14px] hover:bg-gray-300 focus:ring-gray-400 h-8.1 mt--1"
              onClick={handleUploadClick}
              style={{
                height: "2.2rem",
                padding: "0 0.5rem",
                fontWeight: '500', // Apply font-medium equivalent directly
              }}
            >
              Upload file
            </Button>
            <input
              ref={fileInputRef}
              id="companyLogo"
              type="file"
              accept=".csv, .txt, .xlsx, .xls"  
              className="sr-only"
              onChange={handleFileChange}
            />
            </div>
            <span className="text-[14px] text-[#64748B] font-normal">*.csv, *.xlsx, *.txt files up to 25MB</span>
          </div>

          {fileError && <p className="text-sm text-red-500">{fileError}</p>}

          <Button
            type="button"
            onClick={handleCompleteClick}
            disabled={uploading}
            className="w-full mt-2 text-[14px] font-medium text-[#FAFAFA] bg-[#3A85F7]"
          >
            {uploading ? "Uploading..." : "Complete"}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateListDialog;
