import React, { useState, useEffect } from "react";
import { Button } from "../../Components/ui/button";
import { Input } from "../../Components/ui/input";
import { cn } from "../../lib/utils";
import { Badge } from "../../Components/ui/badge";
import { Link } from "react-router-dom";
import {
  DotsHorizontalIcon,
  CalendarIcon,
  FileIcon,
  CaretSortIcon,
  ArrowRightIcon,
} from "@radix-ui/react-icons";
import { FiFilter } from "react-icons/fi";
import { addDays, format } from "date-fns";
import { DateRange } from "react-day-picker";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../Components/ui/popover";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../Components/ui/table";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
  BreadcrumbPage,
} from "../../Components/ui/breadcrumb";
import { Calendar } from "../../Components/ui/calendar";
import { Separator } from "@radix-ui/react-select";
import axios from "axios";
import { Tabs, TabsContent } from "../../Components/ui/tabs";
import { useNavigate, useParams } from "react-router-dom";
//import { ToastContainer, toast } from "react-toastify";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../Components/ui/dropdown-menu";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import config from "../../config.json";
import {
  PlayIcon,
  PauseIcon,
  StopwatchIcon,
  MagnifyingGlassIcon,
  CheckIcon,
} from "@radix-ui/react-icons";
import { CircularProgress } from "@mui/material";
import { Skeleton } from "../../Components/ui/skeleton";
import DropdownMenuDemo from "../../Components/Filter/CampaignDropdown";
import { useDispatch, useSelector } from "react-redux";
import { setCreateBreadCrumb } from "../../State/slices/AdvertiserAccountSlice";
import { useToast } from "../../Components/ui/use-toast";
import { Toaster } from "../../Components/ui/toaster";
import { UseSelector } from "react-redux";
import { RootState } from "../../State/store";
import * as XLSX from "xlsx";
import { University } from "lucide-react";

interface Campaign {
  campaign_id: number;
  campaign_name: string;
  channel_type: string;
  status: string;
  start_date_time: string;
  end_date_time: string;
  campaign_budget: number;
  sent: string;
  Delivered: string;
  Read : string;
  CTR : string;
  Delivery_Rate : string;
  Button_Click : string;
}

type CampaignCheck = {
  campaign_id: number;
  name: string;
};

interface DatePickerWithRangeProps {
  className?: string;
}

interface StatusCellProps {
  status: "Live" | "Pending" | "Paused" | "In review" | "Completed"; // Limit status to specific strings
}

const DatePickerWithRange: React.FC<DatePickerWithRangeProps> = ({
  className,
}) => {
  const [date, setDate] = useState<DateRange | undefined>({
    from: new Date(),
    to: addDays(new Date(), 7),
  });

  return (
    <div className={className}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className={cn(
              "w-[254px] justify-start text-left font-normal mt-0",
              !date && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, "LLL dd, y")} -{" "}
                  {format(date.to, "LLL dd, y")}
                </>
              ) : (
                format(date.from, "LLL dd, y")
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={setDate}
            numberOfMonths={1}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
};

const renderStatusIcon = (status: any) => {
  switch (status) {
    case "Live":
      return <PlayIcon className="text-[#64748B]" />; // Play icon for 'Live'
    case "Pending":
      return <StopwatchIcon className="text-[#64748B]" />; // Stopwatch icon for 'Pending'
    case "Paused":
      return <PauseIcon className="text-[#64748B]" />; // Pause icon for 'Paused'
    case "In review":
      return <MagnifyingGlassIcon className="text-[#64748B]" />; // Magnifying glass icon for 'In review'
    case "Completed":
      return (
        <CheckIcon className="text-[#64748B] rounded-full border border-gray-500" />
      ); // Check icon for 'Completed'
    default:
      return null; // If no match, return nothing
  }
};

const CampaignList: React.FC = () => {
  const [openMenuRowId, setOpenMenuRowId] = useState<number | null>(null);
  const [campaignList, setCampaignList] = useState<Campaign[]>([]);
  const navigate = useNavigate();
  const [isAlertOpen, setIsAlertOpen] = useState(false); // State to control dialog visibility
  const [campaignToDelete, setCampaignToDelete] = useState(null);
  const [currentCampaigns, setCurrentCampaigns] = useState<Campaign[]>([]);

  const [isSorted, setIsSorted] = useState(false);
  const [originalCampaigns, setOriginalCampaigns] = useState(currentCampaigns);

  const [checkboxSelectedRows, setCheckboxSelectedRows] = useState<number[]>(
    []
  );
  const [isAllSelected, setIsAllSelected] = useState(false);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5); // Default 5 rows per page
  const [searchTerm, setSearchTerm] = useState("");
  const [apiUrlAdvAcc, setApiUrlAdvAcc] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [campaignLoading, setCampaignLoading] = useState(true); // For GetCampaignListbyWorkspaceId
  const [columnsLoading, setColumnsLoading] = useState(true); 

  const [filterData, setFilterData] = useState({
    filter: "",
    subFilter: "",
    value: 0,
  });
  const [hasCampaigns, setHasCampaigns] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast();
  const workspaceId = useSelector(
    (state: RootState) => state.authentication.workspace_id
  );

  const [visibleColumns, setVisibleColumns] = useState([
    "campaign_name",
    "channel_type",
    "status",
    "start_date_time",
    "campaign_budget",
    "sent",
    "delivered",
    "read",
    "ctr",
    "delivery_rate",
    "button_click"
  ]);


  const defaultColumns = [
    "campaign_name",
    "channel_type",
    "status",
    "start_date_time",
    "campaign_budget",
    "sent",
  ];
  
  // Merge default and visible columns
  const displayedColumns = [
    ...defaultColumns,
    ...visibleColumns.filter((col) => !defaultColumns.includes(col)),
  ];

  

  //For Date Filter
  const [dateList, setDateList] = useState<string[]>([]);

  const handleCheckboxRowSelect = (id: number) => {
    setCheckboxSelectedRows((prev) => {
      const newSelectedRows = prev.includes(id)
        ? prev.filter((rowId) => rowId !== id)
        : [...prev, id];
      setIsAllSelected(newSelectedRows.length === currentCampaigns.length); // Update `isAllSelected` if all rows are selected
      return newSelectedRows;
    });
  };

  const handleSelectAll = () => {
    if (isAllSelected) {
      setCheckboxSelectedRows([]);
    } else {
      const allIds = currentCampaigns.map((campaign) => campaign.campaign_id);
      setCheckboxSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected);
  };

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch("/config.json");
        const config = await response.json();
        setApiUrlAdvAcc(config.ApiUrlAdvAcc);
      } catch (error) {
        console.error("Error loading config:", error);
      }
    };

    fetchConfig();
  }, []);

  useEffect(() => {
    if (apiUrlAdvAcc) {
      getCampaignList();
    }
  }, [apiUrlAdvAcc]); // Runs when apiUrlAdvAcc is updated

  useEffect(() => {
    if (campaignList.length > 0) {
      setDateListFunction();
    }
  }, [campaignList]); // Dependency on campaignList

  //Filtering
  const filteredCampaigns = campaignList.filter((campaign) => {
    const matchesSearchTerm = searchTerm
      ? campaign.campaign_name.toLowerCase().includes(searchTerm.toLowerCase())
      : true;

    const matchesSubFilter = filterData.subFilter
      ? filterData.filter === "channel"
        ? campaign.channel_type
            .toLowerCase()
            .includes(filterData.subFilter.toLowerCase())
        : filterData.filter === "status"
        ? campaign.status
            .toLowerCase()
            .includes(filterData.subFilter.toLowerCase())
        : filterData.filter === "startedAt"
        ? campaign.start_date_time.split("T")[0] === filterData.subFilter // Compare only the date part
        : true
      : true;

    return matchesSearchTerm && matchesSubFilter;
  });

  // Calculate total pages for filtered campaigns
  const totalPages: number = Math.ceil(filteredCampaigns.length / rowsPerPage);

  useEffect(() => {
    const newCurrentCampaigns = filteredCampaigns.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    );

    setCurrentCampaigns(newCurrentCampaigns);
  }, [filterData, campaignList, currentPage, rowsPerPage, searchTerm]);
  // Only re-run if dependencies change

  // Handle page change
  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleEdit = (campaignId: any, channelType: any) => {
    //console.log("CampaignId : " + campaignId)
    navigate("/navbar/createCampaign", { state: { campaignId, channelType } });
  };

  // Function to open the alert dialog
  const handleDeleteClick = (campaignId: any) => {
    setCampaignToDelete(campaignId); // Store campaign ID to delete
    setIsAlertOpen(true); // Open the alert dialog
  };

  const handleClose = () => {
    setIsAlertOpen(false);
  };

  // Function to handle the actual delete after confirmation

  useEffect(() => {
    console.log(
      "filter data: " +
        filterData.filter +
        " " +
        filterData.subFilter +
        " " +
        filterData.value
    );
  }, [filterData]);

  // Use useEffect to avoid re-render loop
  useEffect(() => {
    if (filterData.filter === "none") {
      setFilterData({ filter: "none", subFilter: "", value: 0 });
    }
  }, [filterData.filter]);



  const getCampaignList = async () => {
    setIsLoading(true); // Show the loader initially
    setCampaignLoading(true);
    try {
      const response = await axios.get(
        `${apiUrlAdvAcc}/GetCampaignListbyWorkspaceId/${workspaceId}`
      );
      if (response.data && response.data.campaignList) {
        const campaigns = response.data.campaignList;
        setCampaignList(campaigns);
        setIsLoading(false); // Hide loader once processing is done
        setHasCampaigns(campaigns.length > 0); // Check if campaigns exist
      } else {
        setIsLoading(false); // Hide loader once processing is done
      }
    } catch (error) {
      console.error("Error fetching campaign list:", error);
      setCampaignList([]); // Handle errors with an empty list
      setHasCampaigns(false);
    } finally {
      setCampaignLoading(false);
    }
  };
  

  const setDateListFunction = () => {
    const uniqueDates = campaignList.reduce(
      (acc: Record<string, string[]>, campaign) => {
        const date = campaign.start_date_time.split("T")[0]; // Extract only the date part (e.g., "2024-12-18")
        if (!acc[date]) {
          acc[date] = []; // Initialize an empty array for this date if it doesn't exist
        }
        acc[date].push(date); // Add the date to the group (if needed for further processing)
        return acc;
      },
      {}
    );
    const datekeys = Object.keys(uniqueDates);
    console.log("Unique Dates", datekeys); // Logs grouped unique dates
    setDateList(datekeys);
  };

  const confirmDelete = async () => {
    try {
      const response = await axios.delete(
        `${apiUrlAdvAcc}/DeleteCampaignById?CampaignId=` + campaignToDelete
      );

      if (response.data.status === "Success") {
        setIsAlertOpen(false);
        const Close = () => {
          getCampaignList();
        };
        Close();
        toast.toast({
          title: "Success",
          description: "The campaign was deleted successfully",
        });

        // Refresh the campaign list
      } else {
        console.error("Delete failed:", response.data.Status_Description);
        toast.toast({
          title: "Error",
          description: "Failed to delete campaign",
        });

        setTimeout(() => {
          /* wait for 1 second */
        }, 1000);
      }
    } catch (e) {
      console.error("Error in deleting campaign", e);
    }
  };

  const handleMenuToggle = (rowId: number) => {
    setOpenMenuRowId(openMenuRowId === rowId ? null : rowId);
  };

  const sortCampaignList = (tableHeader: string) => {
    const sortByField = (
      field: keyof Campaign,
      type: "string" | "number" | "date" = "string"
    ) => {
      const sortedCampaigns = [...campaignList].sort((a, b) => {
        if (type === "number") {
          return Number(a[field]) - Number(b[field]);
        } else if (type === "date") {
          return (
            Date.parse(a[field] as string) - Date.parse(b[field] as string)
          );
        } else {
          return String(a[field]).localeCompare(String(b[field]));
        }
      });
      setOriginalCampaigns(campaignList);
      setCampaignList(sortedCampaigns);
    };

    if (isSorted) {
      setCampaignList(originalCampaigns);
    } else {
      switch (tableHeader) {
        case "ByCampaignName":
          sortByField("campaign_name", "string");
          break;
        case "ByCampaignChannel":
          sortByField("channel_type", "string");
          break;
        case "ByCampaignStatus":
          sortByField("status", "string");
          break;
        case "ByCampaignDate":
          sortByField("start_date_time", "date"); // Sorting by start date
          break;
        case "ByCampaignAmount":
          sortByField("campaign_budget", "number"); // Sorting by budget as a number
          break;
        case "ByCampaignSent":
          sortByField("sent", "number"); // Sorting by sent count as a number
          break;
          case "ByCampaignDelivered":
          sortByField("Delivered", "string"); // Sorting by sent count as a number
          break;
         case "ByRead":
          sortByField("Read", "string"); // Sorting by sent count as a number
          break;
         case "ByCTR":
          sortByField("CTR", "string"); // Sorting by sent count as a number
          break;
         case "ByDeliveryRate":
          sortByField("Delivery_Rate", "string"); // Sorting by sent count as a number
          break;
         case "ByButtonClick":
          sortByField("Button_Click", "string"); // Sorting by sent count as a number
          break;
        default:
          console.warn("Unknown table header");
      }
    }

    setIsSorted(!isSorted);
  };

  // Function to handle checkbox selection
  // const handleRowSelect = (campaignId: CampaignId): void => {
  //   setSelectedRows((prevSelected) =>
  //     prevSelected.includes(campaignId)
  //       ? prevSelected.filter((id) => id !== campaignId)
  //       : [...prevSelected, campaignId]
  //   );
  // };

  useEffect(() => {
    setHasCampaigns(campaignList.length > 0);
  }, [campaignList]);
  // const hasCampaigns = campaignList.length > 0;

  const handleExportButtonClick = () => {
    // Filter columns to include only the desired fields
    const filteredData = filteredCampaigns.map(
      ({
        campaign_id,
        campaign_name,
        channel_type,
        status,
        start_date_time,
        end_date_time,
        campaign_budget,
        
      }) => ({
        campaign_id,
        campaign_name,
        channel_type,
        status,
        start_date_time,
        end_date_time,
        campaign_budget,
      })
    );

    // Create a worksheet
    const worksheet = XLSX.utils.json_to_sheet(filteredData);

    // Create a workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Campaign_List");

    // Export to an xlsx file
    XLSX.writeFile(workbook, "CampaignList.xlsx");
  };

  return (
    <div>
      <Toaster />
      {(isLoading &&  (campaignLoading || columnsLoading )) && (
          <div className="flex flex-col items-center justify-center h-[500px]">
               <CircularProgress color="primary" />
               </div>
         )} 
         <div className="fixed flex justify-end items-end right-0 top-[-15px] z-20 p-4">
            <Button
              onClick={() => {
                dispatch(setCreateBreadCrumb(true));
                navigate("/navbar/createcampaign");
              } }
              className="w-36 text-sm text-[#F8FAFC] font-medium h-[35px] mt-[10px]"
            >
              Create Campaign
            </Button>
          </div>
      
        {hasCampaigns ? (
      <><div>
              {/* Existing table code here */}
             
              <div className="flex flex-wrap items-center justify-between gap-4  !mt-0">
                {/* Search Input */}
                <div className="flex-shrink-0 w-full md:w-auto">
                  <Input
                    placeholder="Search campaign by name..."
                    className="w-full md:w-[350px] text-[14px] font-normal text-[#171717]  !mt-0"
                    //className="w-[350px] text-[14px] mt-2 font-normal text-[#171717]"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)} />
                </div>

                <div className="flex flex-wrap items-center justify-end gap-4 w-full md:w-auto ">
              {/* Date Picker */}
              <div className="flex-shrink-0">
                <DatePickerWithRange className="!mt-0" />
              </div>

                 {/*} <DropdownMenuDemo
                    setFilterData={setFilterData}
                    dateList={dateList} /> */}
                      <div className="flex-shrink-0">
                    <DropdownMenuDemo
                  visibleColumns={visibleColumns}
                  setVisibleColumns={setVisibleColumns} 
                  workspaceId={workspaceId}       
                  setColumnsLoading={setColumnsLoading}

                    /></div>
                    <div className="flex-shrink-0">
                  <Button
                    variant="outline" 
                    className="w-24 text-[#020617] !mt-0"
                    onClick={handleExportButtonClick}
                  >
                    <FileIcon className="mr-2 text-[#020617]" /> Export
                  </Button>
                  </div>
                </div>
              </div>

              <div className="rounded-md border">
              <div className="max-h-[60vh] overflow-y-auto">
                <Table
                  className="rounded-xl whitespace-nowrap border-gray-200  "
                  style={{ color: "#020617", fontSize: "14px" }}
                >
                  <TableHeader
                    className="text-center text-[14px] fore font-medium"
                    style={{ color: "#64748B" }}
                  >
                    <TableRow>
                   {/* {visibleColumns.includes("campaign_name") && */}
                   {displayedColumns.includes("campaign_name") && (
                      <TableHead className="">
                        <div className="flex items-center gap-6 justify-start cursor-pointer">
                          <input
                            type="checkbox"
                            className={`text-muted-foreground ${isAllSelected
                                ? "accent-gray-700 bg-grey-700 text-red-500"
                                : ""}`}
                            checked={isAllSelected}
                            onChange={handleSelectAll} />
                          Campaign name{" "}
                          <CaretSortIcon
                            onClick={() => sortCampaignList("ByCampaignName")}
                            className="cursor-pointer" />
                        </div>
                      </TableHead> )}

                       {/*{visibleColumns.includes("channel_type") &&*/}
                       {displayedColumns.includes("channel_type") && (
                      <TableHead className="text-left">
                        <div className="flex items-center gap-2 justify-start">
                          Channel{" "}
                          <CaretSortIcon
                            onClick={() => sortCampaignList("ByCampaignChannel")}
                            className="cursor-pointer" />
                        </div>
                      </TableHead> )}
                     {/*} {visibleColumns.includes("status") && */}
                     {displayedColumns.includes("status") && (
                      <TableHead className="text-left">
                        <div className="flex items-center gap-2 justify-start">
                          Status{" "}
                          <CaretSortIcon
                            onClick={() => sortCampaignList("ByCampaignStatus")}
                            className="cursor-pointer" />
                        </div>
                      </TableHead> )}
                      {displayedColumns.includes("start_date_time") && (
                   
                      <TableHead className="text-left">
                        <div className="flex items-center gap-2 justify-start">
                          Schedule{" "}
                          <CaretSortIcon
                            onClick={() => sortCampaignList("ByCampaignDate")}
                            className="cursor-pointer" />
                        </div>
                      </TableHead> )}

                    {displayedColumns.includes("campaign_budget") && (
                   
                      <TableHead className="text-left">
                        <div className="flex items-center gap-2 justify-start">
                          Amount{" "}
                          <CaretSortIcon
                            onClick={() => sortCampaignList("ByCampaignAmount")}
                            className="cursor-pointer" />
                        </div>
                      </TableHead> )}

                      {displayedColumns.includes("sent") && (
                      <TableHead className="text-left">
                        <div className="flex items-center gap-2 justify-start">
                          Sent{" "}
                          <CaretSortIcon
                            onClick={() => sortCampaignList("ByCampaignSent")}
                            className="cursor-pointer" />
                        </div>
                      </TableHead> )}
                      
                     {/*} {visibleColumns.includes("delivered") &&  */}
                     {displayedColumns.includes("delivered") && (
                      <TableHead className="text-left">
                        <div className="flex items-center gap-2 justify-start">
                          Delivered{" "}
                          <CaretSortIcon
                            onClick={() => sortCampaignList("BYCampaignDelivered")}
                            className="cursor-pointer" />
                        </div>
                      </TableHead>)}


                     {/* {visibleColumns.includes("read") && */}
                     {displayedColumns.includes("read") && (
                      <TableHead className="text-left">
                        <div className="flex items-center gap-2 justify-start">
                          Read{" "}
                          <CaretSortIcon
                            onClick={() => sortCampaignList("ByRead")}
                            className="cursor-pointer" />
                        </div>
                      </TableHead>)}
                     {/* {visibleColumns.includes("ctr") && */}
                     {displayedColumns.includes("ctr") && (
                      <TableHead className="text-left">
                        <div className="flex items-center gap-2 justify-start">
                          CTR{" "}
                          <CaretSortIcon
                            onClick={() => sortCampaignList("ByCTR")}
                            className="cursor-pointer" />
                        </div>
                      </TableHead>)}

                      {/*{visibleColumns.includes("delivery_rate") && */}
                      {displayedColumns.includes("delivery_rate") && (
                      <TableHead className="text-left">
                        <div className="flex items-center gap-2 justify-start">
                          Delivery rate{" "}
                          <CaretSortIcon
                            onClick={() => sortCampaignList("ByDeliveryRate")}
                            className="cursor-pointer" />
                        </div>
                      </TableHead>)}

                     {/* {visibleColumns.includes("button_click") && */}
                     {displayedColumns.includes("button_click") && (
                      <TableHead className="text-left">
                        <div className="flex items-center gap-2 justify-start">
                          Button click{" "}
                          <CaretSortIcon
                            onClick={() => sortCampaignList("ByButtonClick")}
                            className="cursor-pointer" />
                        </div>
                      </TableHead>)}
                      <TableHead className="text-left"></TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody className="text-left text-[14px] font-normal text-[#020617] ">
                    {currentCampaigns.map((campaign) => {
                      let isSelected;
                      campaignList.map((campaings) => {
                        isSelected = checkboxSelectedRows.includes(
                          campaings.campaign_id
                        );
                      });

                      return (
                        <TableRow
                          key={campaign.campaign_id}
                          className={`${isSelected ? "bg-[#F1F5F9CC]" : ""}`}
                        >
                           {displayedColumns.includes("campaign_name") &&
                          <TableCell className="flex justify-start py-4 text-green-900">
                            <div className="flex items-center gap-6">
                              <input
                                type="checkbox"
                                className={`accent-gray-700 bg-grey-700 text-red-500 ${isAllSelected
                                    ? "accent-gray-700 bg-grey-700 text-red-500"
                                    : ""}`}
                                checked={checkboxSelectedRows.includes(
                                  campaign.campaign_id
                                )}
                                onChange={() => handleCheckboxRowSelect(campaign.campaign_id)} />
                              <span className="text-[#020617]">{campaign.campaign_name}</span>
                            </div>
                          </TableCell>}
                          {displayedColumns.includes("channel_type") &&
                          <TableCell className="py-4 text-[#FFFFFF]">
                            <Badge
                              className={campaign.channel_type === "WhatsApp"
                                ? ""
                                : campaign.channel_type === "SMS"
                                  ? ""
                                  : campaign.channel_type === "Push Notification"
                                    ? ""
                                    : campaign.channel_type === "Email"
                                      ? "bg-blue-400"
                                      : "bg-gray-400"}
                              style={campaign.channel_type === "WhatsApp"
                                ? { backgroundColor: "#479E98" }
                                : campaign.channel_type === "SMS"
                                  ? { backgroundColor: "#DFA548" }
                                  : campaign.channel_type === "Push Notification" ||
                                    campaign.channel_type === "Push"
                                    ? { backgroundColor: "#B87867" }
                                    : campaign.channel_type === "RCS" ||
                                      campaign.channel_type === "RCS messages"
                                      ? { backgroundColor: "#61177E" }
                                      : {}}
                            >
                              {campaign.channel_type}
                            </Badge>
                          </TableCell>}
                          {displayedColumns.includes("status") && 
                          <TableCell className="py-4 flex items-center gap-2">
                            {renderStatusIcon(campaign.status)}{" "}
                            {/* Display the appropriate icon */}
                            {campaign.status} {/* Display the status text */}
                          </TableCell>}
                          {displayedColumns.includes("start_date_time") && 
                          <TableCell className="py-4">
                            <div className="flex items-center gap-2">
                              {new Date(
                                campaign.start_date_time
                              ).toLocaleDateString("en-GB", {
                                day: "numeric",
                                month: "short",
                              })}
                              , {new Date(campaign.start_date_time).getFullYear()}
                              <ArrowRightIcon />
                              {new Date(campaign.end_date_time).toLocaleDateString(
                                "en-GB",
                                {
                                  day: "numeric",
                                  month: "short",
                                }
                              )}
                              , {new Date(campaign.end_date_time).getFullYear()}
                            </div>
                          </TableCell>}
                          {displayedColumns.includes("campaign_budget") && 
                          <TableCell className="py-4 px-4 py-4 px-4 text-center">
                            ${campaign.campaign_budget}
                          </TableCell>}
                          {displayedColumns.includes("sent") &&
                          <TableCell className="py-4 px-4 py-4 px-4 text-center">
                            {campaign.sent}
                          </TableCell>}
                          {displayedColumns.includes("delivered") && 
                          <TableCell className="py-4 px-4 py-4 px-4 text-center">
                            {campaign.Delivered}
                          </TableCell>}
                          {displayedColumns.includes("read") && 
                          <TableCell className="py-4 px-4 py-4 px-4 text-center">
                            {campaign.Read}
                          </TableCell>}
                          {displayedColumns.includes("ctr") && 
                          <TableCell className="py-4 px-4 py-4 px-4 text-center">
                            {campaign.CTR}
                          </TableCell>}
                          {displayedColumns.includes("delivery_rate") && 
                          <TableCell className="py-4 px-4 py-4 px-4 text-center">
                            {campaign.Delivery_Rate}
                          </TableCell>}
                          {displayedColumns.includes("button_click") && 
                          <TableCell className="py-4 px-4 py-4 px-4 text-center">
                            {campaign.Button_Click}
                          </TableCell>}
                          <TableCell className="flex justify-left py-4">
                            <DropdownMenu>
                              <DropdownMenuTrigger asChild>
                                <DotsHorizontalIcon
                                  onClick={() => handleMenuToggle(campaign.campaign_id)}
                                  className="cursor-pointer w-6 h-6" />
                              </DropdownMenuTrigger>
                              {openMenuRowId === campaign.campaign_id && (
                                <DropdownMenuContent
                                  align="end"
                                  className="w-24 bg-gray-200"
                                >
                                  <DropdownMenuItem
                                    onClick={() => handleEdit(
                                      campaign.campaign_id,
                                      campaign.channel_type
                                    )}
                                  >
                                    Edit
                                  </DropdownMenuItem>
                                  <DropdownMenuItem
                                    onClick={() => handleDeleteClick(campaign.campaign_id)}
                                  >
                                    Delete
                                  </DropdownMenuItem>
                                </DropdownMenuContent>
                              )}
                            </DropdownMenu>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                </div>
              </div>

              <Dialog
                open={isAlertOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ "& .MuiPaper-root": { borderRadius: "10px" } }}
              >
                <DialogContent>
                  <DialogContentText>
                    Are you sure you want to delete this campaign ?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button variant="outline" className="w-24" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button className="w-24" onClick={confirmDelete} autoFocus>
                    OK
                  </Button>
                </DialogActions>
              </Dialog>

              {/* Pagination controls */}
            <div className="flex justify-between items-center mt-4">

              <div className="flex items-center space-x-2 text-gray-500 text-sm ">
                <span>{`${(currentPage - 1) * rowsPerPage + 1}-${Math.min(
                  currentPage * rowsPerPage,
                  filteredCampaigns.length
                )} of ${filteredCampaigns.length} row(s) selected`}</span>
              </div>


              <div className="flex items-center space-x-4 font-medium text-sm">
                <span className="text-[#020617] font-medium text-[14px]">Rows per page</span>

                <div className="relative inline-block ml-2">
                  <select
                    className="cursor-pointer border border-gray-300 rounded-md px-2 py-1 appearance-none focus:outline-none focus:ring-1 focus:ring-blue-500"
                    style={{
                      width: "60px",
                      height: "30px",
                      textAlign: "left",
                      fontSize: "12px",
                      fontWeight: "400",
                      borderColor: "#E5E7EB",
                      boxShadow: "inset 0 1px 2px rgba(0, 0, 0, 0.1)", // Adds slight shadow
                    }}
                    value={rowsPerPage}
                    onChange={(e) => {
                      setRowsPerPage(Number(e.target.value));
                      setCurrentPage(1); // Reset to first page after changing rows per page
                    }}
                  >
                    {[5, 10, 20].map((num) => (
                      <option key={num} value={num}>
                        {num}
                      </option>
                    ))}
                  </select>
                  <CaretSortIcon
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none text-gray-500 w-4 h-4"
                  />
                </div>



                <div className="ml-4 mr-4">
                  <span className="text-[#020617] text-[14px] font-medium">{`Page ${currentPage} of ${totalPages}`}</span>
                </div>

              <div className="flex items-center gap-2">
                <button
                  disabled={currentPage === 1}
                  className={`border p-1 pr-2 pl-2 rounded text-gray-500 ${currentPage === 1 ? 'cursor-not-allowed bg-gray-100' : 'hover:bg-gray-200'}`}
                  onClick={() => handlePageChange(1)}
                >
                  «
                </button>
                <button
                  disabled={currentPage === 1}
                  className={`border p-1 pr-2 pl-2 rounded text-gray-500 ${currentPage === 1 ? 'cursor-not-allowed bg-gray-100' : 'hover:bg-gray-200'}`}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  ‹
                </button>
                <button
                  disabled={currentPage === totalPages}
                  className={`border p-1 pr-2 pl-2 rounded text-gray-500 ${currentPage === totalPages ? 'cursor-not-allowed bg-gray-100' : 'hover:bg-gray-200'}`}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  ›
                </button>
                <button
                  disabled={currentPage === totalPages}
                  className={`border p-1 pr-2 pl-2 rounded text-gray-500 ${currentPage === totalPages ? 'cursor-not-allowed bg-gray-100' : 'hover:bg-gray-200'}`}
                  onClick={() => handlePageChange(totalPages)}
                >
                  »
                </button>
              </div>

            </div>
            </div>
            </div></>
      ) : (
        <>
        {isLoading && null}
        {!isLoading && (
        <div className="flex flex-col items-center justify-center h-[500px]">
          <h2 className="text-[24px] font-semibold mb-1 text-[#000000]">
            Here you will see all your campaigns
          </h2>
          <p className="text-[#64748B] font-normal mb-1 text-[14px]">
          Click the button below to create your first campaign.
          </p>
          <Button
          onClick={() => {
            dispatch(setCreateBreadCrumb(true));
            navigate("/navbar/createcampaign");
          }}
          className="inline-flex items-center justify-center text-[14px] font-medium text-[#F8FAFC] px-5 py-5 whitespace-nowrap"
          style={{ width: "auto", minWidth: "unset" }}>        
          Create campaign
        </Button>
        </div>
        )}
        </>
      )}
    </div>
  );
};

export default CampaignList;
