import React, { useState, useEffect } from "react";
import { Button } from "../../../Components/ui/button";
import { Input } from "../../../Components/ui/input";
import { Badge } from "../../../Components/ui/badge";
import {
  DotsHorizontalIcon,
  CaretSortIcon,
} from "@radix-ui/react-icons";


import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../Components/ui/table";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
  BreadcrumbPage,
} from "../../../Components/ui/breadcrumb";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../../Components/ui/use-toast";
import { Toaster } from "../../../Components/ui/toaster";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../Components/ui/dropdown-menu";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {
  PlayIcon,
  PauseIcon,
  StopwatchIcon,
  MagnifyingGlassIcon,
  CheckIcon,
} from "@radix-ui/react-icons";
import { Skeleton } from "../../../Components/ui/skeleton";
import DropdownMenuDemo from "../../../Components/Filter/AccountsDropdown";
import { useDispatch, useSelector } from "react-redux";
import { UseSelector } from "react-redux";
import { setworkspace,setWorkspaceId,setmail } from "../../../State/slices/AuthenticationSlice";
import { SetImpersonator } from "../../../State/slices/AdminSlice";
import { setCreateBreadCrumb } from "../../../State/slices/AdvertiserAccountSlice";
import PersonalInfoPopup from "./PersonalInfoPopUp"
import { RootState } from "@/src/State/store";
import CircularProgress from "@mui/material/CircularProgress";


interface Account {
  accountId: number;
  name: string;
  email: string;
  type: string;
  createdAt: string;
  updatedAt: string;
}

type AccountCheck = {
  accountId: number;
  name: string;
};


//
const Accounts: React.FC = () => {
  const [openMenuRowId, setOpenMenuRowId] = useState<number | null>(null);

  const [accountList, setAccountList] = useState<Account[]>([]);
  const [currentAccounts, setCurrentAccounts] = useState<Account[]>([]);
  const emailId = useSelector((state:RootState)=>state.authentication.userEmail);
  const apiUrlAdvAcc = useSelector((state:RootState)=>state.authentication.apiURL);
  const workspaceId = useSelector((state: RootState) => state.authentication.workspace_id);
  const workspaceName = useSelector((state: RootState) => state.authentication.workspaceName);
  const navigate = useNavigate();


  const [isSorted, setIsSorted] = useState(false);
  const [originalAccounts, setOriginalAccounts] = useState(currentAccounts);

  const [checkboxSelectedRows, setCheckboxSelectedRows] = useState<number[]>([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5); // Default 5 rows per page
  const [searchTerm, setSearchTerm] = useState("");
  const [apiUrlAdminAcc, setapiUrlAdminAcc] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  //For the Pop Up Operation
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState<number>(0);
  const [selectedAccountEmail, setSelectedAccountEmail] = useState<string>("");

  //Tamil
  const [isAlertOpen, setIsAlertOpen] = useState(false); // State to control dialog visibility
  const [delete_wid , setdelete_wid] = useState<number>(0);
  const [delete_personalemail , setdelete_personalemail] = useState<string>("");

  const [selected_type , setSelected_type] = useState<number>(0);

  

  const toast = useToast();


  const [filterData, setFilterData] = useState({
    filter: "All Accounts",
    value: 0,
  });

  
  const [hasAccounts, setHasAccounts] = useState(false);
  const dispatch = useDispatch();



  const handleCheckboxRowSelect = (id: number) => {
    setCheckboxSelectedRows((prev) => {
      const newSelectedRows = prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id];
      setIsAllSelected(newSelectedRows.length === currentAccounts.length); 
      return newSelectedRows;
    });
  };

  const handleSelectAll = () => {
    if (isAllSelected) {
      setCheckboxSelectedRows([]);
    } else {
      const allIds = currentAccounts.map((account) => account.accountId);
      setCheckboxSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected);
  };

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        setIsLoading(true);
        const response = await fetch("/config.json");
        const config = await response.json();
        setapiUrlAdminAcc(config.ApiUrlAdminAcc);

        console.log("apiUrlAdminAcc:" , apiUrlAdminAcc);
      } catch (error) {
        console.error("Error loading config:", error);
      }
    };

    fetchConfig();
  }, []);

  useEffect(() => {
    if (apiUrlAdminAcc) {
      getAccountList();
    }
  }, [apiUrlAdminAcc]); // Runs when apiUrlAdminAcc is updated


  const handleAccountDeletion = async (mailId:any) => {
     
    try {  
    const isConfirmed = window.confirm(
      "If you delete your account, you are the owner of the workspace, so the workspace will also be deleted. Do you want to proceed?"
    );
    if (!isConfirmed) {
      // User canceled the action
      return;
    }
    console.log("mail::" ,mailId );
    const response = await axios.get(`
      ${apiUrlAdvAcc}/DeleteAccountByEmail?email=${mailId}`);
      if (response.status === 200) {
        // const Close = () => {
        //   navigate("/");
        // };
        // Close();
        toast.toast({
          title:"Success",
          description:"Profile Deleted Successfully"
        })
      }
    } catch (error) {
      toast.toast({
        title:"Failed",
        description:"Error in Deletion"
      })
      console.error("Error deleting account:", error);
    }
  };

  const filteredAccounts = accountList.filter((account) => {
    const matchesSearchTerm = searchTerm
      ? account.name.toLowerCase().includes(searchTerm.toLowerCase())
      : true;
  
    const matchesTypeFilter =
      filterData.filter === "All Accounts" || account.type === filterData.filter;
  
    return matchesSearchTerm && matchesTypeFilter;
  });
  
  

  // Calculate total pages for filtered accounts
  const totalPages: number = Math.ceil(filteredAccounts.length / rowsPerPage);

  useEffect(() => {
    const newCurrentAccounts = filteredAccounts.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    );

    setCurrentAccounts(newCurrentAccounts);
  }, [filterData, accountList, currentPage, rowsPerPage, searchTerm]);
  // Only re-run if dependencies change

  // Handle page change
  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };





 

  // Function to handle the actual delete after confirmation

  useEffect(() => {
    console.log(
      "filter data: " +
        filterData.filter +
        " " +
        filterData.value
    );
  }, [filterData]);


  const getWorkspaceDetailsByAccountId = async(accountId:Number) =>{
    try{
      const response = await axios.get(`${apiUrlAdminAcc}/GetWorkspaceDetailsByAccountId?accountId=${accountId}`);
      if(response.data.status==="Success"){
        return {wId:response.data.workspaceData[0].workspaceId, wName:response.data.workspaceData[0].workspaceName}
      }
      else{
        console.error("Error in getting user workspace details")
        return {wId:null,wName:null};
      }
    }catch(error){
      console.error("user workspace details not found due to: ",error);
    }
  }

  const getAccountList = async () => {
    setIsLoading(true);
    try {
      console.log("AccountGet")
      const response = await axios.get(`${apiUrlAdminAcc}/GetAccountList`);
      console.log( "Response : " , response.data.accountList);
      if (response.data && response.data.accountList) {
        setAccountList(response.data.accountList);
        setIsLoading(false);
        console.log("account List : ", response.data.accountList);
      } else {
        console.log("No account list available in response.");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      // Handle error if API call fails
      console.error("Error fetching account list:", error);
    } finally {
      // Ensure the menu is closed after fetching data
    }
  };

 

  const handleMenuToggle = (rowId: number) => {
    setOpenMenuRowId(openMenuRowId === rowId ? null : rowId);
  };

  const sortAccountList = (tableHeader: string) => {
    const sortByField = (
      field: keyof Account,
      type: "string" | "number" | "date" = "string"
    ) => {
      const sortedAccounts = [...accountList].sort((a, b) => {
        if (type === "number") {
          return Number(a[field]) - Number(b[field]);
        } else if (type === "date") {
          return (
            Date.parse(a[field] as string) - Date.parse(b[field] as string)
          );
        } else {
          return String(a[field]).localeCompare(String(b[field]));
        }
      });
      setOriginalAccounts(accountList);
      setAccountList(sortedAccounts);
    };

    if (isSorted) {
      setAccountList(originalAccounts);
    } else {
      switch (tableHeader) {
        case "ByAccountName":
          sortByField("name", "string");
          break;
        case "ByAccountEmail":
          sortByField("email", "string");
          break;
        case "ByAccountType":
          sortByField("type", "string");
          break;
        case "ByAccountCreatedAt":
          sortByField("createdAt", "date"); // Sorting by start date
          break;
        case "ByAccountUpdatedAt":
          sortByField("updatedAt", "date"); // Sorting by start date
          break;

        default:
          console.warn("Unknown table header");
      }
    }

    setIsSorted(!isSorted);
  };


  useEffect(() => {
    setHasAccounts(accountList.length > 0);
  }, [accountList]);
  // const hasAccounts = accountList.length > 0;

  const handleView = (accountId: number , accountEmail: string) => {
    console.log(`View account with ID: ${accountId}`);
    setSelectedAccountId(accountId);
    setSelectedAccountEmail(accountEmail);
    setIsPopupOpen(true);
  };
  
  const handleImpersonate = async(accountId: number, accountEmail: string) => {
    console.log(`Impersonating account with ID: ${accountId}`);
      const response = await getWorkspaceDetailsByAccountId(accountId);
      const Impersonator = {
        ImpersonationState:true,
        ImpersonatorEmail:emailId,
        ImpersonatorWName:workspaceName,
        ImpersonatorWID:workspaceId
      }
      if(response?.wId!=null && response?.wName!=null){
        dispatch(SetImpersonator(Impersonator));
        dispatch(setmail(accountEmail));
        dispatch(setworkspace(response.wName));
        dispatch(setWorkspaceId(response.wId));
        console.log("dispatched workspace details...redirecting...");
        navigate("/navbar/dashboard",{state:{path:response.wName}});
      }
      else{
        console.error("error dispatching workspace details");
      }
  };

  const handleDeleteClick = (accountMail: string) => {
    console.log(`Deleting account with ID: ${accountMail}`);
    handleAccountDeletion(accountMail);
    getAccountList();
  };


  //Personal Account Delete
  const  handlePersonalDeleteClick = (accountEmail: string) => {
    console.log(`Deleting Personal Account : ${accountEmail}`);
    setdelete_personalemail(accountEmail);
    setSelected_type(1);
    setIsAlertOpen(true);
  }


  //Adv Account Delete
  const handleAdvDeleteClick = (accountId: number) => {
    console.log(`Deleting Workspace Account : ${accountId}`);
    setdelete_wid(accountId);
    setSelected_type(2);
    setIsAlertOpen(true); // Open the alert dialog
  }




  const handleClose = () => {
    setIsAlertOpen(false);
    setSelected_type(0);
  };


  const confirmDelete = async () => {
    if(selected_type === 1){
      try {
        console.log(`Deleting Personal Account: ${delete_personalemail}`);
    
        // Indicate loading state
        setIsLoading(true);
    
        // Make the API call
        const response = await axios.get(
          `${apiUrlAdvAcc}/DeleteAccountByEmail?email=${delete_personalemail}`
       );
       const errorMessage = response.data[0]?.status_Description;
        if (response.status === 200) {    
        // Check if the response has the expected status and description
          setIsAlertOpen(false);
          toast.toast({
            title: 'Success',
            description: 'Account Deleted Successfully',
          });
        } else if(errorMessage === "Validation failed: You have invited members within your workspace. So account deletion is not accepted") {
          toast.toast({
            title:"Access denied",
            description:"Access denied. You have members in your workspaces."
          })
        }
        else {
          toast.toast({
            title: 'Error',
            description: 'Something went wrong, please try again.',
          });
        }
      } catch (error) {
        // Handle errors
        toast.toast({
          title: 'Error',
          description: 'Something went wrong, please try again.',
        });
        console.error('Error deleting personal:', error);
      } finally {
        // Reset loading state and refresh the account list
        setSelected_type(0);
        getAccountList();
      }
    }
    else if(selected_type === 2){
      try {
        console.log(`Deleting Workspace Account: ${delete_wid}`);
    
        // Indicate loading state
        setIsLoading(true);
    
        // Make the API call
        const response = await axios.get(`${apiUrlAdvAcc}/deleteworkspce?workspaceid=${delete_wid}`);
    
        // Check if the response has the expected status and description
        if (response.data.status === "Success" && response.data.status_Description) {
          setIsAlertOpen(false);
          toast.toast({
            title: 'Success',
            description: 'Account Deleted Successfully',
          });
        } else {
          toast.toast({
            title: 'Error',
            description: 'Unexpected response from the server.',
          });
        }
      } catch (error) {
        // Handle errors
        toast.toast({
          title: 'Error',
          description: 'Something went wrong, please try again.',
        });
        console.error('Error deleting workspace:', error);
      } finally {
        // Reset loading state and refresh the account list
        setSelected_type(0);
        getAccountList();
      }
    }

  };
  

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  
  return (
    <div>
      <Toaster />

      {isLoading && (
               <div className="flex flex-col items-center justify-center h-[500px]">
                    <CircularProgress color="primary" />
                </div>            
      )}

      {!isLoading && hasAccounts ? (
        <div>
          {/* Existing table code here */}
          <div className="flex  mt-2">
            <div className=" ">
              <Input
                placeholder="Search account by name..."
                className="w-[350px] text-[14px] font-normal text-[#64748B]"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className=" ">
            {/* Filter Button */}
             <DropdownMenuDemo setFilterData={setFilterData} />
            </div>
          </div>

          <div className="rounded-md border">
            <div className="max-h-[60vh] overflow-y-auto">
              <Table className="rounded-xl whitespace-nowrap border-gray-200">
                <TableHeader className="text-center text-[14px] font-medium">
                  <TableRow className="sticky top-0 bg-white z-10">
                    <TableHead>
                      <div className="flex items-center gap-6 justify-start cursor-pointer">
                        <input
                          type="checkbox"
                          className={`text-muted-foreground ${
                            isAllSelected ? "accent-gray-700 bg-grey-700 text-red-500" : ""
                          }`}
                          checked={isAllSelected}
                          onChange={handleSelectAll}
                        />
                        Name{" "}
                        <CaretSortIcon
                          onClick={() => sortAccountList("ByAccountName")}
                          className="cursor-pointer"
                        />
                      </div>
                    </TableHead>

                    <TableHead className="text-left">
                      <div className="flex items-center gap-2 justify-start">
                        Email{" "}
                        <CaretSortIcon
                          onClick={() => sortAccountList("ByAccountEmail")}
                          className="cursor-pointer"
                        />
                      </div>
                    </TableHead>

                    <TableHead className="text-left">
                      <div className="flex items-center gap-2 justify-start">
                        Type{" "}
                        <CaretSortIcon
                          onClick={() => sortAccountList("ByAccountType")}
                          className="cursor-pointer"
                        />
                      </div>
                    </TableHead>

                    <TableHead className="text-left">
                      <div className="flex items-center gap-2 justify-start">
                        Created at{" "}
                        <CaretSortIcon
                          onClick={() => sortAccountList("ByAccountCreatedAt")}
                          className="cursor-pointer"
                        />
                      </div>
                    </TableHead>

                    <TableHead className="text-left">
                      <div className="flex items-center gap-2 justify-start">
                        Updated at{" "}
                        <CaretSortIcon
                          onClick={() => sortAccountList("ByAccountUpdatedAt")}
                          className="cursor-pointer"
                        />
                      </div>
                    </TableHead>

                    <TableHead className="text-left"></TableHead>
                  </TableRow>
                </TableHeader>

                <TableBody className="text-left text-[14px] font-normal text-[#020617]">
                  {currentAccounts.map((account) => {
                    let isSelected;
                    accountList.map((accounts) => {
                      isSelected = checkboxSelectedRows.includes(accounts.accountId);
                    });

                    return (
                      <TableRow
                        key={account.accountId}
                        className={`${isSelected ? "bg-gray-200" : ""}`}
                      >
                        <TableCell className="py-4 text-green-900">
                          <div className="flex items-center gap-6">
                            <input
                              type="checkbox"
                              className={`accent-gray-700 bg-grey-700 text-red-500 ${
                                isAllSelected ? "accent-gray-700 bg-grey-700 text-red-500" : ""
                              }`}
                              checked={checkboxSelectedRows.includes(account.accountId)}
                              onChange={() => handleCheckboxRowSelect(account.accountId)}
                            />
                            <span
                              style={{
                                color: "#020617",
                                fontSize: "14px",
                                fontWeight: "400",
                                whiteSpace: "nowrap", // Prevent text wrapping
                              }}
                            >
                              {account.name}
                            </span>
                          </div>
                        </TableCell>

                        <TableCell className="py-4">
                          <span style={{ color: "#020617", fontSize: "14px", fontWeight: "400" }}>
                            {account.email}
                          </span>
                        </TableCell>

                        <TableCell className="py-4">
                        <span style={{ color: "#020617", fontSize: "14px", fontWeight: "400" }}>
                            {account.type}
                        </span>
                        </TableCell>

                        <TableCell className="py-4">
                          <div className="flex items-center gap-2">
                            <span
                              style={{
                                color: "#020617",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            >
                              {new Date(account.createdAt).toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              })}{" "}
                              ∙{" "}
                              {new Date(account.createdAt).toLocaleTimeString("en-GB", {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </span>
                          </div>
                        </TableCell>

                        <TableCell className="py-4">
                          <div className="flex items-center gap-2">
                            <span
                              style={{
                                color: "#020617",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            >
                              {new Date(account.updatedAt).toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              })}{" "}
                              ∙{" "}
                              {new Date(account.updatedAt).toLocaleTimeString("en-GB", {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </span>
                          </div>
                        </TableCell>


                        <TableCell className="flex justify-left py-4">
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <DotsHorizontalIcon
                                onClick={() => handleMenuToggle(account.accountId)}
                                className="cursor-pointer w-6 h-6"
                              />
                            </DropdownMenuTrigger>
                            {openMenuRowId === account.accountId && (
                              <DropdownMenuContent
                                align="end"
                                className="w-24"
                              >
                                {account.type === "Personal" && (
                                  <>
                                    <DropdownMenuItem
                                      className="cursor-pointer"
                                      onClick={() =>
                                        handleView(account.accountId, account.email)
                                      }
                                    >
                                      View
                                    </DropdownMenuItem>
                                    <DropdownMenuItem
                                      className="cursor-pointer"
                                      onClick={() =>
                                        handleImpersonate(account.accountId, account.email)
                                      }
                                    >
                                      Impersonate
                                    </DropdownMenuItem>
                                    <DropdownMenuItem
                                      className="cursor-pointer"
                                      onClick={() => handlePersonalDeleteClick(account.email)}
                                    >
                                      Delete Personal Account
                                    </DropdownMenuItem>
                                  </>
                                )}
                                {account.type === "Advertiser" && (
                                  <>
                                    <DropdownMenuItem
                                      className="cursor-pointer"
                                      onClick={() =>
                                        handleView(account.accountId, account.email)
                                      }
                                    >
                                      View
                                    </DropdownMenuItem>
                                    <DropdownMenuItem
                                      className="cursor-pointer"
                                      onClick={() =>
                                        handleAdvDeleteClick(account.accountId)
                                      }
                                    >
                                      Delete Advertiser Account
                                    </DropdownMenuItem>
                                  </>
                                )}
                                {account.type === "Operator" && (
                                  <>
                                    <DropdownMenuItem
                                      className="cursor-pointer"
                                      onClick={() =>
                                        handleView(account.accountId, account.email)
                                      }
                                    >
                                      View
                                    </DropdownMenuItem>
                                    <DropdownMenuItem
                                      className="cursor-pointer"
                                      onClick={() => handleDeleteClick(account.email)}
                                    >
                                      Delete Telco Account
                                    </DropdownMenuItem>
                                  </>
                                )}
                              </DropdownMenuContent>
                            )}
                            <PersonalInfoPopup
                            isOpen={isPopupOpen}
                            onClose={handleClosePopup}
                            accountId={selectedAccountId}
                            accountEmail={selectedAccountEmail}
                            />
                          </DropdownMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </div>

          <Dialog
                open={isAlertOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ "& .MuiPaper-root": { borderRadius: "10px" } }}
              >
                <DialogContent>
                  <DialogContentText>
                    Are you sure you want to delete this Account ?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button variant="outline" className="w-24" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button className="w-24" onClick={confirmDelete} autoFocus>
                    OK
                  </Button>
                </DialogActions>
              </Dialog>


        

          {/* Pagination controls */}
<div className="flex justify-between items-center mt-4">

  {/* Row information */}
  <div className="flex items-center space-x-2 text-gray-500 text-sm">
    <span>{`${(currentPage - 1) * rowsPerPage + 1}-${Math.min(
      currentPage * rowsPerPage,
      accountList.length
    )} of ${accountList.length} row(s) selected`}</span>
  </div>

  {/* Pagination controls */}
  <div className="flex items-center space-x-4 font-medium text-sm">
    <span className="text-[#020617] font-medium text-[14px]">Rows per page</span>

    {/* Rows per page dropdown */}
    <div className="relative inline-block ml-2">
      <select
        className="cursor-pointer border border-gray-300 rounded-md px-2 py-1 appearance-none focus:outline-none focus:ring-1 focus:ring-blue-500"
        style={{
          width: "60px",
          height: "30px",
          textAlign: "left",
          fontSize: "12px",
          fontWeight: "400",
          borderColor: "#E5E7EB",
          boxShadow: "inset 0 1px 2px rgba(0, 0, 0, 0.1)", // Adds slight shadow
        }}
        value={rowsPerPage}
        onChange={(e) => {
          setRowsPerPage(Number(e.target.value));
          setCurrentPage(1); // Reset to first page after changing rows per page
        }}
      >
        {[5, 10, 20].map((num) => (
          <option key={num} value={num}>
            {num}
          </option>
        ))}
      </select>
      <CaretSortIcon
        className="absolute right-2 top-1/2 transform -translate-y-1/2 pointer-events-none text-gray-500 w-4 h-4"
      />
    </div>

    {/* Page info */}
    <div className="ml-4 mr-4">
      <span className="text-[#020617] text-[14px] font-medium">{`Page ${currentPage} of ${totalPages}`}</span>
    </div>

    {/* Navigation buttons */}
    <div className="flex items-center gap-2">
      <button
        disabled={currentPage === 1}
        className={`border p-1 pr-2 pl-2 rounded text-gray-500 ${
          currentPage === 1 ? 'cursor-not-allowed bg-gray-100' : 'hover:bg-gray-200'
        }`}
        onClick={() => handlePageChange(1)}
      >
        «
      </button>
      <button
        disabled={currentPage === 1}
        className={`border p-1 pr-2 pl-2 rounded text-gray-500 ${
          currentPage === 1 ? 'cursor-not-allowed bg-gray-100' : 'hover:bg-gray-200'
        }`}
        onClick={() => handlePageChange(currentPage - 1)}
      >
        ‹
      </button>
      <button
        disabled={currentPage === totalPages}
        className={`border p-1 pr-2 pl-2 rounded text-gray-500 ${
          currentPage === totalPages ? 'cursor-not-allowed bg-gray-100' : 'hover:bg-gray-200'
        }`}
        onClick={() => handlePageChange(currentPage + 1)}
      >
        ›
      </button>
      <button
        disabled={currentPage === totalPages}
        className={`border p-1 pr-2 pl-2 rounded text-gray-500 ${
          currentPage === totalPages ? 'cursor-not-allowed bg-gray-100' : 'hover:bg-gray-200'
        }`}
        onClick={() => handlePageChange(totalPages)}
      >
        »
      </button>
    </div>
  </div>
</div>

        </div>
      ) : (
        <>
              {isLoading && null}
              {!isLoading && (
              <div className="flex flex-col items-center justify-center h-[500px]">
                <h2 className="text-[24px] font-semibold mb-1 text-[#000000]">
                  Here you will see all accounts
                </h2>
              </div>
              )}
        </>
      )}
    </div>
  );
};

export default Accounts;
