import axios from "axios";
import React, { useEffect, useState } from "react";

interface ViewAccountPopupProps {
  isOpen: boolean;
  onClose: () => void;
  accountId: number;
  accountEmail: string;
}

const PersonalInfoPopup: React.FC<ViewAccountPopupProps> = ({
  isOpen,
  onClose,
  accountId,
  accountEmail,
}) => {
  const [apiUrlAdvAcc, setApiUrlAdvAcc] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");


  // Load configuration on mount
  useEffect(() => {
    const loadConfig = async () => {
      try {
        const response = await fetch("/config.json");
        const config = await response.json();
        setApiUrlAdvAcc(config.ApiUrlAdvAcc); // Set the API URL from config
      } catch (error) {
        console.error("Error loading config:", error);
      }
    };
    loadConfig();
  }, []);

  // Fetch personal info when the popup is open and dependencies are ready
  useEffect(() => {
    const GetPersonalInfo = async () => {
      try {
        console.log("Fetching Personal Info...");
        const response = await axios.post(`${apiUrlAdvAcc}/GetPersonalinfoByEmail`, {
          UserEmail: accountEmail,
        });
        console.log("Response:", response.data);
        if (response.data.status === "Success" && response.data.personalInfoList.length > 0) {
          const user = response.data.personalInfoList[0];
          setFirstName(user.firstName);
          setLastName(user.lastName);
        } else {
          console.log("No personal info found for the given email.");
        }
      } catch (error) {
        console.error("Error fetching personal info:", error);
      }
    };

    if (isOpen && apiUrlAdvAcc && accountEmail) {
      GetPersonalInfo();
    }
  }, [isOpen, apiUrlAdvAcc, accountEmail]);


  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-20 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-auto max-w-[80%] min-w-[30%]">
        <h2 className="text-lg font-semibold text-gray-800 mb-4 text-center">Account Details</h2>
  
        <div className="grid grid-cols-2 gap-y-4">
          {/* Account ID */}
          <span className="text-14px font-medium text-[#020617]">Account ID:</span>
          <span className="text-[14px] font-normal">{accountId}</span>
  
          {/* Account Email */}
          <span className="text-14px font-medium text-[#020617]">Account Email:</span>
          <span className="text-[14px] font-normal">{accountEmail}</span>
  
          {/* First Name */}
          <span className="text-14px font-medium text-[#020617]">First Name:</span>
          <span className="text-[14px] font-normal">{firstName || "Loading..."}</span>
  
          {/* Last Name */}
          <span className="text-14px font-medium text-[#020617]">Last Name:</span>
          <span className="text-[14px] font-normal">{lastName || "Loading..."}</span>
        </div>
  
        <button
          className="mt-6 w-full font-medium text-[#FAFAFA] bg-[#3A85F7] text-sm px-4 py-2 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
  
  
  
  
};

export default PersonalInfoPopup;
